import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import cns from "classnames";
import {
  fetchTicketCode,
  getTicketCode,
  setTicketCode,
  getUserInfo,
} from "../services/api.js";
import { userIsNotSubscribed, userPlan } from "../user";
import {
  NYA_FREE,
  NYA_MONTHLY,
  NYA_UNLIMITEDV2_MONTHLY,
} from "../util/contants/url_constants";

class Ticket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: null,
      loading: false,
    };
  }

  componentDidMount() {
    const { entryID, accountPage } = this.props;
    if (entryID === this.props.ticket.id) {
      accountPage.scrollTop = this.wrapper.offsetTop;
    }
  }

  render() {
    const {
      ticket,
      onCopy,
      assignedCodes,
      isUserSubscribed,
      currentUserPlan,
      userInfo,
      addAssignedCodes,
    } = this.props;
    const { loading } = this.state;

    const date = moment.utc(ticket.date);
    const code =
      this.state.code || getTicketCode(`${userInfo.user_id}-${ticket.id}`);
    const notAllowedPlans = [NYA_FREE, NYA_MONTHLY, NYA_UNLIMITEDV2_MONTHLY];
    const planVerification = !notAllowedPlans.includes(currentUserPlan);
    const userData = this.props.userData;
    const isSubscriptionTrial = userData && userData.isSubscriptionTrial;
    const showCode =
      isUserSubscribed && planVerification && code && !isSubscriptionTrial;

    if (isUserSubscribed && planVerification && !code && !isSubscriptionTrial) {
      assignedCodes &&
        assignedCodes.map((code) => {
          if (code.ticket === ticket.id) {
            setTicketCode(`${userInfo.user_id}-${ticket.id}`, code.code);
            this.setState({ code: code.code });
          }
        });
    }

    const onCodeButtonClick = () => {
      if (!isUserSubscribed || !planVerification || isSubscriptionTrial) {
        window.subsTicketMonthly();
        return;
      }
      if (code) {
        onCopy(code);
      } else {
        // window.ticketsUnavailable() // REMOVE THIS LATER ONCE CONTETNFUL IS WORKING
        // return
        const { staticCode, flexibleCodeSource } = ticket;
        if (staticCode) {
          this.setState({ code: staticCode, loading: false });
          addAssignedCodes({ ticket: ticket.id, code: staticCode });
        } else {
          this.setState({ loading: true });
          fetchTicketCode(ticket.id, ticket.flexibleCodeSource)
            .then((result) => {
              this.setState({ code: result.body.code, loading: false });
              addAssignedCodes({ ticket: ticket.id, code: result.body.code });
            })
            .catch((err) => {
              console.error(err);
              this.setState({ loading: false });
            });
        }
      }
    };

    if (ticket.style === "1") {
      return (
        <div
          ref={(el) => (this.wrapper = el)}
          className={cns("ticket ticket--nya", {
            "ticket--soldOut": ticket.soldOut,
          })}
        >
          <div className="month">{date.format("MMM")}</div>
          <div className="number">{date.format("DD")}</div>
          <div className="year">{date.format("YYYY")}</div>
          <div className="ticket-title">{ticket.title}</div>
          <div className="ticket-descrOne">{ticket.description}</div>
          <div className="ticket-descrTwo">
            <label>{ticket.venue}</label>
            <label>{ticket.location}</label>
          </div>
          {showCode && (
            <div className="ticket-codeSection">
              <label>
                CODE: <label className="code">{code}</label>
              </label>
              <label>{ticket.codeLabel}</label>
            </div>
          )}
          {ticket.buyLinkEnabled && ticket.buyLinkUrl && (
            <div
              className={cns("button ticket-button", {
                disabled: ticket.soldOut,
              })}
              onClick={() => {
                if (!ticket.soldOut) window.open(ticket.buyLinkUrl);
              }}
            >
              BUY TICKETS
            </div>
          )}
          {(ticket.flexibleCodeSource || ticket.staticCode) && (
            <div
              className={cns(
                "button code-button",
                { white: showCode },
                { disabled: ticket.soldOut || loading }
              )}
              onClick={!loading && !ticket.soldOut && onCodeButtonClick}
            >
              {showCode ? "COPY CODE" : loading ? "" : "GET CODE"}
            </div>
          )}
          {loading && (
            <div className="loading-indicator-wrapper">
              <div className="loading-indicator" />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          ref={(el) => (this.wrapper = el)}
          className={cns("ticket ticket--public", {
            "ticket--soldOut": ticket.soldOut,
          })}
        >
          <div className="month">{date.format("MMM")}</div>
          <div className="number">{date.format("DD")}</div>
          <div className="year">{date.format("YYYY")}</div>
          <div className="ticket-title">{ticket.title}</div>
          <div className="ticket-descrOne">{ticket.description}</div>
          <div className="ticket-descrTwo">
            <label>{ticket.venue}</label>
            <label>{ticket.location}</label>
          </div>
          {ticket.buyLinkEnabled && ticket.buyLinkUrl && (
            <div
              className={cns("button ticket-button", {
                disabled: ticket.soldOut,
              })}
              onClick={() => {
                if (!ticket.soldOut) window.open(ticket.buyLinkUrl);
              }}
            >
              BUY TICKETS
            </div>
          )}
        </div>
      );
    }
  }
}

export default class TicketsPanel extends Component {
  render() {
    const isUserSubscribed = !userIsNotSubscribed();
    const currentUserPlan = userPlan();
    const userInfo = getUserInfo();

    const onCopy = (code) => {
      const targetId = "_hiddenCopyText_";
      let target = document.createElement("textarea");
      target.style.position = "absolute";
      target.style.left = "-9999px";
      target.style.top = "0";
      target.id = targetId;
      document.body.appendChild(target);
      target.textContent = code;

      const currentFocus = document.activeElement;
      target.focus();
      target.setSelectionRange(0, target.value.length);

      // copy the selection
      let succeed;
      try {
        succeed = document.execCommand("copy");
      } catch (e) {
        succeed = false;
      }
      // restore original focus
      if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
      }

      target.textContent = "";

      return succeed;
    };

    const ticketsBefore = this.props.data.tickets
      .filter((item) => moment.utc(item.date) < moment.utc().startOf("day"))
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    const ticketsAfter = this.props.data.tickets
      .filter((item) => moment.utc(item.date) >= moment.utc().startOf("day"))
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    const tickets = ticketsAfter.concat(ticketsBefore);
    return (
      <div className="panel tickets-panel">
        <div className="title">Tickets</div>
        <div className="tickets-content">
          <span className="tickets-message">
            NYA Yearly Subscribers get the best seats first. Click "Get Code" to
            reveal your unique pre-sale code. Enjoy the show(s)!!
          </span>
          <span className="tickets-message">
            If you are not a NYA subscriber{" "}
            <Link to="/account?screen=plans">CLICK HERE</Link> to become one.
          </span>
          <span className="tickets-message">
            For code issues <Link to="/contact">contact</Link> NYA
          </span>
          <span className="tickets-message">
            For purchase issues please contact the ticket vendor.
          </span>
        </div>
        {tickets &&
          tickets.map((ticket, ind) => (
            <Ticket
              assignedCodes={this.props.data.assignedCodes}
              ticket={ticket}
              key={`ticket-${ind}`}
              onCopy={onCopy}
              isUserSubscribed={isUserSubscribed}
              currentUserPlan={currentUserPlan}
              userInfo={userInfo}
              entryID={this.props.entryID}
              accountPage={this.props.accountPage}
              addAssignedCodes={this.props.addAssignedCodes}
              userData={this.props.userData}
            />
          ))}
      </div>
    );
  }
}
