import {
  SET_PPV_DATA,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_FEATURED,
  SET_PPV_MOVIE,
  SET_PURCHASE_STATE,
} from "../constants";
import store from "../store";

export const updatePpvData = (data) =>
  store.dispatch({ type: SET_PPV_DATA, data });
export const updateSelectedIndex = (data) =>
  store.dispatch({ type: SET_SELECTED_CATEGORY, data });
export const updateFeaturedIndex = (data) =>
  store.dispatch({ type: SET_SELECTED_FEATURED, data });
export const updatePpvMovie = (data) =>
  store.dispatch({ type: SET_PPV_MOVIE, data });
export const updatePurchaseState = (data) =>
  store.dispatch({ type: SET_PURCHASE_STATE, data });
